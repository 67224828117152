.App {
    width: 414px;
    height: 736px;
    background-image: url('assets/cold-bg.jpeg');
    background-size: cover;
    background-position: bottom;
    transition: 0.4s ease;
}

.App.warm {
    background-image: url('assets/warm-bg.jpeg');
}

main {
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.75));
    padding: 30px;
}

.search_bar {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 20px;
    outline: none;
    background-color: rgba(255, 255, 255, 0.5);
    color: #313131;
    font-size: 16px;
    transition: 0.5s ease;
    box-shadow: 0px 5px rgba(0, 0, 0, 0.2);
}

.search_bar:focus {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(5px);
    box-shadow: none;
}

.location, .date {
    text-align: center;
    color: #fff;
    text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
    font-weight: 500;
}

.location {
    font-size: 30px;
    margin-top: 50px;
}

.date {
    font-size: 20px;
    margin: 5px;
}

.temp {
    margin: 30px auto;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 15px 25px;
    width: fit-content;
    font-size: 102px;
    color: #fff;
    font-weight: 900;
    border-radius: 16px;
    text-shadow: 3px 6px rgba(50, 50, 70, 0.5);
    box-shadow: 3px 6px rgba(0, 0, 0, 0.2);
}

.weather {
    text-align: center;
    color: #fff;
    font-size: 48px;
    font-weight: 700;
    text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
}

@media screen and (max-width: 414px) {
    .App {
        width: 100vw;
        height: 100vh;
    }
}